nav {
    height: 5rem;
    background-color: var(--header-background);
    padding: 0 2rem 0rem;
    margin: 0 0 2rem;
    

    /* Flex box */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
}

.Page-Links {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    
    > h3 * {
        transition: all .2s;
        text-decoration: none;
        color: var(--light-font);
    }
    .active {
        color: #e42b47;
    }
    :hover {
        color: #d29fb1
    }
    
}

@media (max-width: 768px) {
    nav {
        padding: 0 .5rem;
    }
    .Page-Links {
        gap: 1rem;
    }
}
