:root {
  --dark-background: #020817;
  --light-font: #f8fafc;
  --box-red: #760012;
  --header-background: #071845;
  --footer-background: #081a43;
}
*, 
*::before, 
*::after {
  box-sizing: border-box;
}
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: ui-sans-serif,system-ui,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: var(--dark-background);
  color: var(--light-font);
  text-align: center;
  
  line-height: 1.6;

  /* 45-75 characters per line */
  

}


/* Mobile container utility class */
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

/* Media queries -- mobile-first (min-width) implementation */
/* xs */
@media (min-width: 475px) {
  .container {
    max-width: 475px;
  }
}

/* sm */
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

/* md */
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

/* lg */
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

/* xl */
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

/* 2xl */
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
