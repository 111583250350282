.home {
    width: 40%;
    margin: 0 auto 0;
   
    text-align: left;

}

header {
    margin-bottom: 4rem;
    text-align: center;
} 

section {
    margin-bottom: 3rem;
}

.photo-selfie {
    width: 100%;
    max-width: 800px;
    border-radius: 1rem;
}

ul {
    padding-left: 2rem;
}

@media (max-width: 768px) {
    .home {
        width: 90%;
    }
}